import firstProject from "./project/firstProject";
import secondProject from "./project/secondProject";
import thirdProject from "./project/thirdProject";


const projectContent = {
    "first": firstProject,
    "second": secondProject,
    "third": thirdProject,
};

export default projectContent;