import { VStack } from "@chakra-ui/react";
import React from "react";
import Banner from "../shared/Banner/Banner";



const Education = () => {
    return (
        <VStack>
            <Banner />
        </VStack>
    );
}

export default Education;